import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { getMarketOverviewMetrics } from 'graphql/queries';

const dateMinus30 = () => {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  return date;
};

const datePlus1 = () => {
  let currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 1);
  return currentDate;
};

const dateToFormat = (yourDate) => {
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() + offset * 60 * 1000);
  return yourDate.toISOString().split('T')[0];
};

export const fetchMarketingOverview = createAsyncThunk(
  'leads/fetchMarketingOverview',
  async (
    { clientId, createdAtStart, createdAtEnd, limit = 500, nextToken, filters, sortField, sortDirection = 'DESC' },
    { rejectWithValue }
  ) => {
    try {
      const variables = {
        clientId: clientId,
        from: createdAtStart ? new Date(createdAtStart).toISOString() : null,
        until: createdAtEnd ? new Date(new Date(createdAtEnd).getTime() + 24 * 60 * 60 * 1000).toISOString() : null,
        channelIds: filters?.ChannelIDs,
        leadsLimit: 50,
        LeadsNextToken: null,
      };

      const { data } = await API.graphql({
        query: getMarketOverviewMetrics,
        variables: {
          clientId: clientId,
          ...variables,
        },
      });
      const result = data.getMarketOverviewMetrics;
      return result;
    } catch (error) {
      return rejectWithValue(error.message || `Failed to fetch marketing overview details ${JSON.stringify(error)}`);
    }
  }
);

const marketingOverviewSlice = createSlice({
  name: 'marketingOverview',
  initialState: {
    marketingOverviewData: null,
    loading: false,
    error: null,
    clientId: '',
    createdAtStart: dateToFormat(dateMinus30()),
    createdAtEnd: dateToFormat(datePlus1()),

    filters: [],
  },
  reducers: {
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    setCreatedAtStart: (state, action) => {
      state.createdAtStart = action.payload;
    },
    setCreatedAtEnd: (state, action) => {
      state.createdAtEnd = action.payload;
    },
    resetMarketingOverviewData: (state) => {
      state.marketingOverviewData = null;
      state.nextToken = null;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    resetFilters(state) {
      state.filters = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarketingOverview.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMarketingOverview.fulfilled, (state, action) => {
        state.loading = false;
        state.marketingOverviewData = action.payload;
      })
      .addCase(fetchMarketingOverview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  resetMarketingOverviewData,
  setPage,
  setFilters,
  resetFilters,
  setClientId,
  setCreatedAtStart,
  setCreatedAtEnd,
} = marketingOverviewSlice.actions;

export default marketingOverviewSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { leadsByClientID } from 'graphql/queries';

const dateMinus30 = () => {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  return date;
};

const datePlus1 = () => {
  let currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 1);
  return currentDate;
};

const dateToFormat = (yourDate) => {
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() + offset * 60 * 1000);
  return yourDate.toISOString().split('T')[0];
};

const generateMultiSelectQuery = (filter) => {
  const { name, value } = filter;

  const containsConditions = value.map((selectedValue) => ({
    [name]: { contains: selectedValue },
  }));

  return { or: containsConditions };
};

const generateExistenceQuery = (filter) => {
  const { name, value } = filter;

  let conditions = [];

  let finalCondition = [];

  if (value?.length >= 2) return null;

  value.forEach((selectedValue) => {
    if (selectedValue === 'Job Created') {
      conditions.push({
        [name]: { attributeExists: true },
      });
      conditions.push({
        [name]: { ne: null },
      });
      finalCondition.push({
        and: conditions,
      });
    } else {
      conditions.push({
        [name]: { attributeExists: false },
      });
      conditions.push({
        [name]: { eq: null },
      });

      finalCondition.push({
        or: conditions,
      });
    }
  });

  return { or: finalCondition };
};

const generateFinalFilterQuery = (filters) => {
  const groupedFilters = {};

  // Group filters by `name`
  filters.forEach(({ name, type, operator, value, filterType }) => {
    if (filterType === 'client') return; // Skip client-side filter

    if (!groupedFilters[name]) {
      groupedFilters[name] = [];
    }

    let filterCondition;
    if (type === 'multi-select') {
      filterCondition = generateMultiSelectQuery({ name, value });
    } else if (type === 'exists') {
      filterCondition = generateExistenceQuery({ name, value });
    } else {
      switch (operator) {
        case 'equals':
          filterCondition = { [name]: { eq: value } };
          break;
        case 'not equal':
          filterCondition = { [name]: { ne: value } };
          break;
        case 'contains':
          filterCondition = { [name]: { contains: value } };
          break;
        case 'greater than':
          filterCondition = { [name]: { ge: parseInt(value) * 60 } };
          break;
        case 'less than':
          filterCondition = { [name]: { le: parseInt(value) * 60 } };
          break;
        default:
          filterCondition = null;
      }
    }

    if (filterCondition) {
      groupedFilters[name].push(filterCondition);
    }
  });

  const finalQuery = Object.values(groupedFilters).map((filtersArray) => {
    if (filtersArray.length > 1) {
      return { or: filtersArray };
    }
    return filtersArray[0];
  });

  return finalQuery.filter(Boolean);
};

const matchesField = (fieldValue, filter) => {
  if (!filter) return true;

  switch (filter.operator) {
    case 'equals':
      return fieldValue === filter.value;
    case 'contains':
      return fieldValue?.includes(filter.value);
    case 'not equals':
      return fieldValue !== filter.value;
    default:
      return true; // Default behavior for unspecified operators
  }
};

const getFilter = (filters, n) => {
  return filters.find(({ name }) => name === n || null);
};

const filterLeads = (leads, filters) => {
  const utm = getFilter(filters, 'utm');
  const sourceMedium = getFilter(filters, 'sourceMedium');
  const utmPromotion = getFilter(filters, 'utmPromotion');
  const utmCampaign = getFilter(filters, 'utmCampaign');
  const utmTerm = getFilter(filters, 'utmTerm');

  const selectedSourceMediumValues =
    sourceMedium?.value?.map((val) => {
      const [utmSource, utmMedium] = val.split('/');
      return { utmSource, utmMedium };
    }) || [];

  // Step 1: Filter leads based on WebSession data (sourceMedium)
  let filteredLeads = leads.filter((lead) => {
    if (selectedSourceMediumValues.length > 0) {
      return selectedSourceMediumValues.some(
        (sessionFilter) =>
          (!sessionFilter.utmSource || lead?.WebSession?.utmSource === sessionFilter.utmSource) &&
          (!sessionFilter.utmMedium || lead?.WebSession?.utmMedium === sessionFilter.utmMedium)
      );
    }
    return true;
  });

  // Step 2: Filter leads based on UTM existance
  if (utm?.value?.length > 0) {
    filteredLeads = filteredLeads.filter((lead) => {
      const checks = [];

      if (utm?.value?.includes('term')) {
        checks.push(!!lead?.WebSession?.utmTerm);
      }

      if (utm?.value?.includes('promotion')) {
        checks.push(!!lead?.WebSession?.utmPromotion);
      }

      if (utm?.value?.includes('campaign')) {
        checks.push(!!lead?.WebSession?.utmCampaign);
      }

      return !checks.includes(false);
    });
  }

  // Step 3: Filter leads based on UTM matched value
  filteredLeads = filteredLeads.filter((lead) => {
    const matchesPromotion = matchesField(lead?.WebSession?.utmPromotion, utmPromotion);
    const matchesCampaign = matchesField(lead?.WebSession?.utmCampaign, utmCampaign);
    const matchesTerm = matchesField(lead?.WebSession?.utmTerm, utmTerm);

    return matchesPromotion && matchesCampaign && matchesTerm;
  });

  // Return the filtered leads if any filtering conditions were applied
  if (utmPromotion || utmCampaign || utmTerm || selectedSourceMediumValues.length > 0 || utm) {
    return filteredLeads;
  }

  return leads; // Return original leads if no filters applied
};

export const fetchLeads = createAsyncThunk(
  'leads/fetchLeads',
  async (
    { clientId, createdAtStart, createdAtEnd, limit = 500, nextToken, filters, sortField, sortDirection = 'DESC' },
    { rejectWithValue }
  ) => {
    try {
      const queryFilters = generateFinalFilterQuery(filters);

      const variables = {
        limit,
        nextToken: nextToken || null,
        createdAt: {
          between: [
            createdAtStart ? new Date(createdAtStart).toISOString() : null,
            createdAtEnd ? new Date(new Date(createdAtEnd).getTime() + 24 * 60 * 60 * 1000).toISOString() : null,
          ],
        },
        sortDirection,
      };

      const { data } = await API.graphql({
        query: leadsByClientID,
        variables: {
          ClientID: clientId,
          ...variables,
          filter: { and: queryFilters },
        },
      });

      let finalLeads = data.leadsByClientID.items;
      finalLeads = filterLeads(finalLeads, filters);

      return {
        items: finalLeads,
        nextToken: data.leadsByClientID.nextToken || null,
        total: finalLeads.length,
      };
    } catch (error) {
      return rejectWithValue(error.message || `Failed to fetch leads ${JSON.stringify(error)}`);
    }
  }
);

const leadsSlice = createSlice({
  name: 'leads',
  initialState: {
    items: [],
    nextToken: null,
    total: 0,
    loading: false,
    error: null,
    clientId: '',
    createdAtStart: dateToFormat(dateMinus30()),
    createdAtEnd: dateToFormat(datePlus1()),
    limit: 500, // Set default limit
    reduxGlobalFilter: '',
    totalLeads: undefined,
    filters: [],
  },
  reducers: {
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    setCreatedAtStart: (state, action) => {
      state.createdAtStart = action.payload;
    },
    setCreatedAtEnd: (state, action) => {
      state.createdAtEnd = action.payload;
    },
    resetLeads: (state) => {
      state.items = [];
      state.nextToken = null;
      state.total = 0;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setReduxGlobalFilter(state, action) {
      state.reduxGlobalFilter = action.payload;
    },

    setTotalLeads(state, action) {
      state.totalLeads = action.payload;
    },

    resetFilters(state) {
      state.filters = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeads.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        state.loading = false;

        // If new leads are fetched, append them to the existing leads list
        if (action.payload.items.length > 0) {
          // Dispatch the appendLeads action to update the state
          state.items = [...state.items, ...action.payload.items]; // This is equivalent to dispatching appendLeads
        }
        state.nextToken = action.payload.nextToken;
        state.total = action.payload.total; // Update total leads count
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  resetLeads,
  setPage,
  setFilters,
  resetFilters,
  setReduxGlobalFilter,
  setClientId,
  setCreatedAtStart,
  setCreatedAtEnd,
  setTotalLeads,
} = leadsSlice.actions;
export default leadsSlice.reducer;

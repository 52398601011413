import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { leadsByClientID } from 'graphql/queries';

const dateMinus30 = () => {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  return date;
};

const datePlus1 = () => {
  let currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 1);
  return currentDate;
};

const dateToFormat = (yourDate) => {
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() + offset * 60 * 1000);
  return yourDate.toISOString().split('T')[0];
};

const generateMultiSelectQuery = (channelIds) => {
  // Generate base conditions
  const containsConditions = channelIds
    .filter((id) => id !== 'unspecified') // Exclude 'unspecified' for now
    .map((selectedValue) => ({
      ChannelID: { contains: selectedValue },
    }));

  // Add the condition for 'unspecified' if it exists in the array
  if (channelIds.includes('unspecified')) {
    containsConditions.push({
      ChannelID: { attributeExists: false },
    });
  }

  return { or: containsConditions };
};

export const fetchMarketingLeads = createAsyncThunk(
  'leads/fetchMarketingLeads',
  async (
    { clientId, createdAtStart, createdAtEnd, limit = 500, nextToken, filters, sortField, sortDirection = 'DESC' },
    { rejectWithValue }
  ) => {
    let queryFilters = null;
    try {
      queryFilters = generateMultiSelectQuery(filters.ChannelIDs);
      const variables = {
        limit,
        nextToken: nextToken || null,
        createdAt: {
          between: [
            createdAtStart ? new Date(createdAtStart).toISOString() : null,
            createdAtEnd ? new Date(new Date(createdAtEnd).getTime() + 24 * 60 * 60 * 1000).toISOString() : null,
          ],
        },
        sortDirection,
      };

      const { data } = await API.graphql({
        query: leadsByClientID,
        variables: {
          ClientID: clientId,
          ...variables,
          filter: { and: queryFilters },
        },
      });

      let finalLeads = data.leadsByClientID.items;

      return {
        marketingLeads: finalLeads,
        nextToken: data.leadsByClientID.nextToken || null,
        total: finalLeads.length,
      };
    } catch (error) {
      return rejectWithValue(error.message || `Failed to fetch leads ${JSON.stringify(error)}`);
    }
  }
);

const marketingLeadsSlice = createSlice({
  name: 'MOleads',
  initialState: {
    marketingLeads: [],
    nextToken: null,
    total: 0,
    loading: false,
    error: null,
    clientId: '',
    createdAtStart: dateToFormat(dateMinus30()),
    createdAtEnd: dateToFormat(datePlus1()),
    limit: 500, // Set default limit
    reduxGlobalFilter: '',
    totalLeads: undefined,
    filters: [],
  },
  reducers: {
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    setCreatedAtStart: (state, action) => {
      state.createdAtStart = action.payload;
    },
    setCreatedAtEnd: (state, action) => {
      state.createdAtEnd = action.payload;
    },
    resetMarketingLeads: (state) => {
      state.marketingLeads = [];
      state.nextToken = null;
      state.total = 0;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setReduxGlobalFilter(state, action) {
      state.reduxGlobalFilter = action.payload;
    },

    setTotalLeads(state, action) {
      state.totalLeads = action.payload;
    },

    resetFilters(state) {
      state.filters = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarketingLeads.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMarketingLeads.fulfilled, (state, action) => {
        state.loading = false;

        // If new leads are fetched, append them to the existing leads list
        if (action.payload.marketingLeads.length > 0) {
          // Dispatch the appendLeads action to update the state
          state.marketingLeads = [...state.marketingLeads, ...action.payload.marketingLeads]; // This is equivalent to dispatching appendLeads
        }
        state.nextToken = action.payload.nextToken;
        state.total = action.payload.total; // Update total leads count
      })
      .addCase(fetchMarketingLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  resetMarketingLeads,
  setPage,
  setFilters,
  resetFilters,
  setReduxGlobalFilter,
  setClientId,
  setCreatedAtStart,
  setCreatedAtEnd,
  setTotalLeads,
} = marketingLeadsSlice.actions;

export default marketingLeadsSlice.reducer;

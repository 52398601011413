// material-ui
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';

// assets
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/reducers/snackbar';
import { CloseOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { getWhatconvertsRecordingAuth } from 'services/whatconverts/api';
import { API } from 'aws-amplify';
import useAuth from 'hooks/useAuth';
import { updateUsers } from 'graphql/mutations';
import Snackbar from '../../../../components/@extended/Snackbar';
import { useLocation, useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { PROFITFILL_USER_TYPES } from 'utils/constants';

const clientsByTenantID = /* GraphQL */ `
  query ClientsByTenantID(
    $TenantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByTenantID(
      TenantID: $TenantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        TenantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

const listClients = /* GraphQL */ `
  query ListClients($filter: ModelClientsFilterInput, $limit: Int, $nextToken: String) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        TenantID
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

const updateUser = async (id, clientView, version) => {
  var userObject = {
    id,
    clientView,
    _version: version,
  };
  const apiData = await API.graphql({
    query: updateUsers,
    variables: {
      input: userObject,
    },
  });
  var us = apiData.data.updateUsers;
  return us;
};

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const Search = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState('');
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState('');

  function usePluralList() {
    let query = null;
    let variables = { limit: 3000 };

    if (user?.type === PROFITFILL_USER_TYPES.TENANT || user?.type === PROFITFILL_USER_TYPES.CLIENT) {
      query = clientsByTenantID;
      variables.TenantID = user?.TenantID;
    } else {
      query = listClients;
    }
    return useQuery({
      enabled: !!user,
      queryKey: ['ClientView', user?.type],
      queryFn: async () => {
        if (!query) {
          return []; // Return empty array if query is null
        }
        const response = await API.graphql({
          query,
          variables,
        });
        let clientsList =
          user?.type === PROFITFILL_USER_TYPES.ADMIN
            ? response.data.listClients?.items
            : response.data.clientsByTenantID?.items;
        return clientsList?.filter((i) => !i._deleted).sort((a, b) => a.name.localeCompare(b.name));
      },
    });
  }

  const { status: statusClients, data: dataClients, error: errorClients } = usePluralList();

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseOutlined fontSize="small" />
      </IconButton>
    </>
  );
  useEffect(() => {
    if (user) {
      setValue(user?.clientView ? user.clientView : '');

      if (user?.clientView) {
        const authorizeService = async (service, storageKey, authFunc) => {
          try {
            const response = await authFunc(user.clientView, user.email);
            const token = response.Authorization.split(' ')[1];
            localStorage.setItem(storageKey, token);
          } catch (error) {
            console.error(`Error authorizing ${service} recording:`, error);
          }
        };
        authorizeService('WhatConverts', 'whatconvertsRecordingAuth', getWhatconvertsRecordingAuth);
      }
    }
  }, [user]);

  return (
    <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
      <FormControl sx={{ width: { xs: '100%', md: 224 } }}>
        <InputLabel id="demo-simple-select-label">Viewing Client:</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          // defaultValue={value}
          label="Client"
          onChange={(e) => {
            setValue(e.target.value);
            if (user?.id && user?._version) {
              updateUser(user?.id, e.target.value ? e.target.value : null, user?._version)
                .then((data) => {
                  if (location?.pathname === '/') {
                    navigate(0);
                  } else {
                    navigate('/');
                    navigate(0);
                  }
                  dispatch(
                    openSnackbar({
                      open: true,
                      message: e.target.value
                        ? `View as Client... ${e.target.value}`
                        : `Successfully Exited Client View.`,
                      variant: 'alert',
                      alert: {
                        color: 'success',
                      },
                      close: false,
                    })
                  );
                })
                .catch((err) => {
                  console.log(err);
                  dispatch(
                    openSnackbar({
                      open: true,
                      message: 'Error Changing Client View.',
                      variant: 'alert',
                      alert: {
                        color: 'error',
                      },
                      close: false,
                    })
                  );
                });
            }
          }}
        >
          <MenuItem key={'no client'} value={''}>
            None
          </MenuItem>
          {[PROFITFILL_USER_TYPES.TENANT, PROFITFILL_USER_TYPES.ADMIN].includes(user?.type) &&
            dataClients?.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c?.name}
              </MenuItem>
            ))}
          {user?.type === PROFITFILL_USER_TYPES.CLIENT &&
            user?.Clients?.items
              ?.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
              ?.map((cu) => {
                if (!cu?._deleted) {
                  return (
                    <MenuItem key={cu.id} value={cu.clientsId}>
                      {dataClients?.find((c) => c.id === cu.clientsId)?.name}
                    </MenuItem>
                  );
                }
              })}
        </Select>
      </FormControl>

      <Snackbar open={true} autoHideDuration={5000} onClose={handleClose} message={result} action={action} />
    </Box>
  );
};

export default Search;
